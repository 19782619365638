<template>
  <b-collapse :id="id" v-model="isShown" class="toggle-menu">
    <header class="area-navigation">
      <div class="uu-link">
        <b-img
          :src="require('@/assets/images/uu-logo.svg')"
          :alt="$t('general.uppsalaUniversity')"
          class="uu-link-img"
        />
      </div>
      <div class="site-header">
        <b-link :to="{ name: 'home' }" class="site-header-text">
          {{ $t("general.siteName") }}
        </b-link>
      </div>
      <div class="sticky-top nav-sticky-top">
        <nav><NavigationMain /></nav>
      </div>
    </header>
  </b-collapse>
</template>

<script>
export default {
  name: "NavigationArea",
  components: {
    NavigationMain: () => import("@/components/NavigationMain")
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    isShown: {
      get() {
        return this.show;
      },
      set() {
        // ignore
      }
    }
  }
};
</script>
